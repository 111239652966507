package com.vrajatcreations.components.layout

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.vrajatcreations.components.sections.Footer
import com.vrajatcreations.components.sections.NavHeader
import com.vrajatcreations.style.PageContentStyle
import kotlinx.browser.document
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.css.percent

@Composable
fun PageLayout(
    title: String,
    content: @Composable ColumnScope.() -> Unit
) {
    val breakpoint = rememberBreakpoint()
    LaunchedEffect(title) {
        document.title = "VRaJaT Creations - $title"
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .fontFamily("Poppins")
            .minHeight(100.percent)
            .gridTemplateRows { size(1.fr); size(minContent) }
    ) {
        Column(
            Modifier
                .fillMaxSize()
                .gridRow(1),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            NavHeader(breakpoint)
            Column(
                PageContentStyle.toModifier(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }


        }
        Footer(Modifier.fillMaxWidth().gridRow(2), breakpoint)
    }

}