package com.vrajatcreations.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.fa.FaWhatsapp
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.vrajatcreations.pages.HomeImageKeyFrame
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.css.*

@Composable
fun WhatsAppButton(
    breakpoint: Breakpoint
) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.End
    ) {
        Link(
            "https://api.whatsapp.com/send?phone=9448913955",
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        ) {
            Button(
                onClick = {},
                type = ButtonType.Button,
                modifier = Modifier.padding(0.5.cssRem).borderRadius(5.cssRem)
                    .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f))
                    .background(Colors.Green)
                    .animation(
                        WhatsAppButtonKeyFrame.toAnimation(
                            duration = 1.s,
                            iterationCount = AnimationIterationCount.Infinite,
                            timingFunction = AnimationTimingFunction.EaseInOut,
                            direction = AnimationDirection.Alternate
                        )
                    )
            ) {
                FaWhatsapp(
                    modifier = Modifier.fillMaxSize().color(Colors.White),
                    size = if (breakpoint >= Breakpoint.MD) IconSize.XXL else IconSize.SM
                )
            }
        }
    }
}

val WhatsAppButtonKeyFrame by Keyframes {
    from {
        Modifier.translateX((-25).percent)
    }
    to {
        Modifier
    }
}