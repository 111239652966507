package com.vrajatcreations.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.icons.fa.FaPeopleCarryBox
import com.varabyte.kobweb.silk.components.icons.fa.FaUserTie
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.vrajatcreations.components.layout.PageLayout
import com.vrajatcreations.style.SlideDownAnim
import com.vrajatcreations.style.SlideLeftAnim
import com.vrajatcreations.style.SlideRightAnim
import com.vrajatcreations.util.Res
import org.jetbrains.compose.web.css.*

@Page("/our-team")
@Composable
fun OurTeamPage() {
    val breakpoint = rememberBreakpoint()

    Box(
        Modifier.fillMaxSize(),
        contentAlignment = Alignment.Center
    ) {
        PageLayout("Our Team") {
            Row(
                Modifier.fillMaxWidth()
                    .animation(
                        SlideDownAnim.toAnimation(
                            1.s,
                            timingFunction = AnimationTimingFunction.EaseIn,
                            direction = AnimationDirection.Normal
                        )
                    )
                    .padding(0.5.cssRem)
                    .borderRadius(0.8.cssRem)
                    .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                FaPeopleCarryBox(
                    Modifier
                        .align(Alignment.CenterVertically)
                        .color(Color.rgb(47, 49, 144)),
                    size = IconSize.XXL
                )
                SpanText(
                    text = "We Are Strong Team",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 1.4.cssRem else 0.7.cssRem)
                        .fontWeight(800)
                        .color(Color.rgb(47, 49, 144))
                        .padding(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                )
                FaPeopleCarryBox(
                    Modifier
                        .align(Alignment.CenterVertically)
                        .color(Color.rgb(47, 49, 144)),
                    size = IconSize.XXL
                )
            }
            Res.ourTeamList.forEach {
                Row(
                    Modifier.fillMaxWidth(95.percent)
                        .animation(
                            if (Res.ourTeamList.indexOf(it) % 2 == 0) {
                                SlideRightAnim.toAnimation(
                                    1.s,
                                    timingFunction = AnimationTimingFunction.EaseIn,
                                    direction = AnimationDirection.Normal
                                )
                            } else {
                                SlideLeftAnim.toAnimation(
                                    1.s,
                                    timingFunction = AnimationTimingFunction.EaseIn,
                                    direction = AnimationDirection.Normal
                                )
                            }

                        )
                        .margin(1.cssRem)
                        .padding(0.5.cssRem)
                        .borderRadius(0.8.cssRem)
                        .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    SimpleGrid(
                        numColumns = numColumns(base = 1, md = 2)
                    ) {
                        Column(
                            Modifier.fillMaxSize(),
                            verticalArrangement = Arrangement.Center,
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            Row(
                                Modifier.fillMaxSize()
                                    .margin(top = if (breakpoint >= Breakpoint.MD) 2.cssRem else 1.cssRem),
                                horizontalArrangement = Arrangement.Center
                            ) {
                                FaUserTie(
                                    Modifier
                                        .color(Res.SKY_BLUE_COLOR),
                                    size = IconSize.X9
                                )
                            }
                            Row(
                                Modifier.fillMaxSize()
                            ) {
                                Column(
                                    Modifier.fillMaxWidth(),
                                    horizontalAlignment = Alignment.CenterHorizontally
                                ) {
                                    SpanText(
                                        it.name,
                                        modifier = Modifier
                                            .fillMaxHeight()
                                            .margin(top = 0.4.cssRem)
                                            .fontSize(if (breakpoint >= Breakpoint.MD) 1.2.cssRem else 1.cssRem)
                                            .fontWeight(850)
                                            .color(Color.rgb(47, 49, 144))
                                    )
                                    SpanText(
                                        it.designation,
                                        modifier = Modifier
                                            .fillMaxHeight()
                                            .margin(top = 0.5.cssRem)
                                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.8.cssRem else 0.6.cssRem)
                                            .color(Color.rgb(34, 7, 247))
                                    )
                                }
                            }
                        }
                        Column(
                            Modifier.fillMaxSize(),
                            verticalArrangement = Arrangement.Center
                        ) {
                            SpanText(
                                it.about,
                                modifier = Modifier
                                    .fillMaxHeight()
                                    .align(Alignment.CenterHorizontally)
                                    .margin(1.cssRem)
                                    .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                                    .textAlign(TextAlign.Start)
                                    .color(Colors.Black)
                            )
                        }
                    }

                }
            }


        }
    }
}