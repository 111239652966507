package com.vrajatcreations.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaServicestack
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.vrajatcreations.pages.HomeImageKeyFrame
import com.vrajatcreations.style.SlideDownAnim
import com.vrajatcreations.style.SlideRightAnim
import com.vrajatcreations.util.Res
import org.jetbrains.compose.web.css.*

@Composable
fun OurServices(
    breakpoint: Breakpoint
) {
    Row(
        Modifier.flexWrap(FlexWrap.Wrap)
            .animation(
                SlideDownAnim.toAnimation(
                    1.s,
                    timingFunction = AnimationTimingFunction.EaseIn,
                    direction = AnimationDirection.Normal
                )
            )
            .padding(0.5.cssRem)
            .borderRadius(0.8.cssRem)
            .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        FaServicestack(
            Modifier
                .align(Alignment.CenterVertically)
                .color(Color.rgb(47, 49, 144)),
            size = IconSize.XXL
        )
        SpanText(
            text = "Our Sevices",
            modifier = Modifier
                .fontSize(if (breakpoint >= Breakpoint.MD) 1.4.cssRem else 0.75.cssRem)
                .fontWeight(800)
                .color(Color.rgb(47, 49, 144))
                .padding(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
        )
        FaServicestack(
            Modifier
                .align(Alignment.CenterVertically)
                .color(Color.rgb(47, 49, 144))
                .rotateY(180.deg),
            size = IconSize.XXL
        )
    }
    Row(
        Modifier.fillMaxWidth(90.percent)
            .animation(
                SlideRightAnim.toAnimation(
                    1.s,
                    timingFunction = AnimationTimingFunction.EaseIn,
                    direction = AnimationDirection.Normal
                )
            )
            .margin(1.cssRem)
            .padding(0.5.cssRem),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        SimpleGrid(
            numColumns = numColumns(base = 1, md = 3)
        ) {
            Res.services.forEach {
                Column(
                    Modifier.fillMaxWidth(95.percent)
                        .margin(topBottom = if(breakpoint>=Breakpoint.MD)0.cssRem else 1.cssRem)
                        .borderRadius(0.8.cssRem)
                        .boxShadow(
                            0.05.cssRem,
                            0.05.cssRem,
                            0.3.cssRem,
                            0.05.cssRem,
                            Color.rgba(47, 49, 144, 0.4f)
                        )
                        .animation(
                            HomeImageKeyFrame.toAnimation(
                                duration = if (Res.services.indexOf(it) % 2 == 0) 0.5.s else 0.7.s,
                                iterationCount = AnimationIterationCount.Infinite,
                                timingFunction = AnimationTimingFunction.EaseInOut,
                                direction = AnimationDirection.Alternate
                            )
                        ),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Row(
                        Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Center
                    ) {
                        Image(
                            src = it.imageString,
                            Modifier.fillMaxWidth(95.percent)
                        )
                    }
                    Row(
                        Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Center
                    ) {
                        SpanText(
                            text = it.name,
                            modifier = Modifier
                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.2.cssRem else 0.6.cssRem)
                                .fontWeight(950)
                                .color(Color.rgb(47, 49, 144))
                                .padding(1.cssRem)
                        )
                    }
                }
            }

        }
    }
}