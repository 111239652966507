package com.vrajatcreations.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.icons.fa.FaDochub
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.vrajatcreations.components.layout.PageLayout
import com.vrajatcreations.style.SlideDownAnim
import com.vrajatcreations.style.SlideRightAnim
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Ul

@Page("/privacy-policy")
@Composable
fun PrivacyPolicy() {
    val breakpoint = rememberBreakpoint()
    Box(
        Modifier.fillMaxSize(),
        contentAlignment = Alignment.Center
    ) {
        PageLayout("Privacy Policy") {
            Row(
                Modifier.fillMaxWidth()
                    .position(Position.Relative)
                    .animation(
                        SlideDownAnim.toAnimation(
                            1.s,
                            timingFunction = AnimationTimingFunction.EaseIn,
                            direction = AnimationDirection.Normal
                        )
                    )
                    .padding(0.5.cssRem)
                    .borderRadius(0.8.cssRem)
                    .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                FaDochub(
                    Modifier
                        .align(Alignment.CenterVertically)
                        .color(Color.rgb(47, 49, 144)),
                    size = IconSize.XXL
                )
                SpanText(
                    text = "Privacy Policy",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 1.2.cssRem else 0.6.cssRem)
                        .fontWeight(550)
                        .color(Color.rgb(47, 49, 144))
                        .padding(1.cssRem)
                )
                FaDochub(
                    Modifier
                        .align(Alignment.CenterVertically)
                        .color(Color.rgb(47, 49, 144)),
                    size = IconSize.XXL
                )
            }
            Column(
                Modifier.fillMaxWidth()
                    .position(Position.Relative)
                    .animation(
                        SlideRightAnim.toAnimation(
                            1.s,
                            timingFunction = AnimationTimingFunction.EaseIn,
                            direction = AnimationDirection.Normal
                        )
                    )
                    .margin(1.cssRem)
                    .padding(1.cssRem)
                    .borderRadius(1.cssRem)
                    .boxShadow(0.05.cssRem, 0.05.cssRem, 0.1.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.Start
            ) {
                SpanText(
                    "Effective Date: 09 April 2024",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "VRaJaT Creations (\"we,\" \"us,\" or \"our\") is committed to protecting the privacy of our users. This Privacy Policy describes how we collect, use, disclose, and safeguard your information when you use our mobile applications and web applications (collectively, the \"Services\"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Services.",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the \"Effective Date\" of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Services after the date such revised Privacy Policy is posted.",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "Information We Collect",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 5.cssRem)
                        .color(Colors.Black)
                        .fontWeight(950)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "We may collect personal information from you, such as your mobile number and email address, when you use our Services. We collect this information when you voluntarily provide it to us or when you provide it as part of using our Services.",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "How We Use Your Information",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 5.cssRem)
                        .color(Colors.Black)
                        .fontWeight(950)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "We may use the information we collect from you to:",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                Ul {
                    SpanText(
                        text = "Provide, operate, and maintain our Services;",
                        modifier = Modifier
                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                            .color(Colors.Black)
                            .padding(0.5.cssRem)
                    )
                }
                Ul {
                    SpanText(
                        text = "Improve, personalize, and expand our Services;",
                        modifier = Modifier
                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                            .color(Colors.Black)
                            .padding(0.5.cssRem)
                    )
                }
                Ul {
                    SpanText(
                        text = "Communicate with you, including responding to your comments, questions, and requests;",
                        modifier = Modifier
                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                            .color(Colors.Black)
                            .padding(0.5.cssRem)
                    )
                }
                Ul {
                    SpanText(
                        text = "Send you technical notices, updates, security alerts, and support and administrative messages;",
                        modifier = Modifier
                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                            .color(Colors.Black)
                            .padding(0.5.cssRem)
                    )
                }
                Ul {
                    SpanText(
                        text = "Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.",
                        modifier = Modifier
                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                            .color(Colors.Black)
                            .padding(0.5.cssRem)
                    )
                }
                SpanText(
                    text = "Sharing Your Information",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 5.cssRem)
                        .color(Colors.Black)
                        .fontWeight(950)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "We may share your information with third parties only in the ways that are described in this Privacy Policy. We may share your information with:",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                Ul {
                    SpanText(
                        text = "Service providers who need access to such information to carry out work on our behalf;",
                        modifier = Modifier
                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                            .color(Colors.Black)
                            .padding(0.5.cssRem)
                    )
                }
                Ul {
                    SpanText(
                        text = "Business partners who we collaborate with to deliver our Services;",
                        modifier = Modifier
                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                            .color(Colors.Black)
                            .padding(0.5.cssRem)
                    )
                }
                Ul {
                    SpanText(
                        text = "Any competent law enforcement body, regulatory body, government agency, court, or other third party where we believe disclosure is necessary to comply with applicable laws or regulations.",
                        modifier = Modifier
                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                            .color(Colors.Black)
                            .padding(0.5.cssRem)
                    )
                }
                SpanText(
                    text = "Your Choices",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 5.cssRem)
                        .color(Colors.Black)
                        .fontWeight(950)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "You have the right to opt-out of receiving marketing communications from us. You can do this by following the unsubscribe instructions provided in the marketing emails you receive from us.",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "Data Security",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 5.cssRem)
                        .color(Colors.Black)
                        .fontWeight(950)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please understand that no data transmission or storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us.",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "Contact Us",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 5.cssRem)
                        .color(Colors.Black)
                        .fontWeight(950)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "If you have any questions or concerns about this Privacy Policy, please contact us at vrajatcreations@gmail.com",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "By using our Services, you agree to the collection and use of your information in accordance with this Privacy Policy.",
                    modifier = Modifier
                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
            }
        }
    }
}