package com.vrajatcreations.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.setVariable
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.style.addVariantBase
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val CircleButtonVariant by ButtonStyle.addVariantBase {
    Modifier
        .padding(0.px)
        .borderRadius(50.percent)
}

val UnColoredButtonVariant by ButtonStyle.addVariantBase {
    Modifier
        .setVariable(
            ButtonVars.BackgroundDefaultColor,
            Colors.Transparent
        )
}