package com.vrajatcreations.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.Keyframes
import org.jetbrains.compose.web.css.percent

val SlideDownAnim by Keyframes {
    from {
        Modifier.translateY((-5).percent)
    }
    to {
        Modifier
    }
}


val SlideRightAnim by Keyframes {
    from {
        Modifier.translateX((-5).percent)
    }
    to {
        Modifier
    }
}

val SlideLeftAnim by Keyframes {
    from {
        Modifier.translateX((10).percent)
    }
    to {
        Modifier
    }
}

val SlideUpAnim by Keyframes {
    from {
        Modifier.translateY((20).percent)
    }
    to {
        Modifier
    }
}