package com.vrajatcreations.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.vrajatcreations.pages.HomeImageKeyFrame
import com.vrajatcreations.style.SlideRightAnim
import com.vrajatcreations.style.SlideUpAnim
import org.jetbrains.compose.web.css.*

@Composable
fun HomeLandingPage(
    breakpoint: Breakpoint,
    ctx: PageContext
) {
    Row(
        Modifier.fillMaxWidth()
            .margin(bottom = 2.cssRem)
    ) {
        SimpleGrid(
            numColumns(base = 1, md = 2)
        ) {
            Column(
                Modifier.fillMaxWidth()
                    .margin(if (breakpoint >= Breakpoint.MD) 2.cssRem else 1.cssRem)
            ) {
                Row(
                    Modifier.align(Alignment.CenterHorizontally)
                        .animation(
                            SlideRightAnim.toAnimation(
                                1.s,
                                timingFunction = AnimationTimingFunction.EaseIn,
                                direction = AnimationDirection.Normal,
                                fillMode = AnimationFillMode.Forwards
                            )
                        )
                ) {
                    SpanText(
                        "Welcome to",
                        modifier = Modifier.fontSize(if (breakpoint >= Breakpoint.MD) 2.cssRem else 1.cssRem)
                            .fontWeight(300)
                    )
                    SpanText(
                        " VRaJaT Creations",
                        modifier = Modifier.fontSize(if (breakpoint >= Breakpoint.MD) 2.cssRem else 1.cssRem)
                            .fontWeight(1000)
                            .color(Color.rgb(47, 49, 144))
                    )
                }
                Row(
                    Modifier.align(Alignment.Start)
                        .animation(
                            SlideUpAnim.toAnimation(
                                1.s,
                                timingFunction = AnimationTimingFunction.EaseIn,
                                direction = AnimationDirection.Normal,
                                fillMode = AnimationFillMode.Forwards
                            )
                        )
                        .flexWrap(FlexWrap.Wrap)
                        .margin(top = 1.cssRem),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    SpanText(
                        "We",
                        modifier = Modifier.fontSize(if (breakpoint >= Breakpoint.MD) 3.cssRem else 1.5.cssRem)
                            .fontWeight(300)
                    )
                    SpanText(
                        " design and",
                        modifier = Modifier.fontSize(if (breakpoint >= Breakpoint.MD) 3.2.cssRem else 1.6.cssRem)
                            .fontWeight(1000)
                            .color(Colors.Blue)
                    )
                    SpanText(
                        " develop",
                        modifier = Modifier.fontSize(if (breakpoint >= Breakpoint.MD) 3.2.cssRem else 1.6.cssRem)
                            .fontWeight(1000)
                            .color(Colors.Blue)
                    )
                    SpanText(
                        "Android & iOS Apps",
                        modifier = Modifier.fontSize(if (breakpoint >= Breakpoint.MD) 3.5.cssRem else 1.75.cssRem)
                            .fontWeight(300)
                            .color(Color.rgb(47, 49, 144))
                    )
                    SpanText(
                        " also",
                        modifier = Modifier.fontSize(if (breakpoint >= Breakpoint.MD) 3.5.cssRem else 1.75.cssRem)
                            .fontWeight(300)
                    )
                    SpanText(
                        "Web Apps.",
                        modifier = Modifier.fontSize(if (breakpoint >= Breakpoint.MD) 3.5.cssRem else 1.75.cssRem)
                            .fontWeight(300)
                            .color(Color.rgb(47, 49, 144))
                    )
                    SpanText(
                        "Our team of skilled developers and designers work tirelessly to provide top-notch digital solutions that meet your business needs",
                        modifier = Modifier.fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                            .color(Colors.BlueViolet)
                            .margin(top = if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                            .fontWeight(300)
                    )
                }
                Row(
                    Modifier.fillMaxWidth()
                        .align(Alignment.Start)
                        .animation(
                            SlideRightAnim.toAnimation(
                                1.s,
                                timingFunction = AnimationTimingFunction.EaseIn,
                                direction = AnimationDirection.Normal,
                                fillMode = AnimationFillMode.Forwards
                            )
                        )
                        .margin(top = 1.cssRem),
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Button(
                        onClick = {
                            ctx.router.navigateTo("contact-us")
                        },
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(if (breakpoint >= Breakpoint.MD) 4.cssRem else 2.cssRem)
                            .backgroundColor(Colors.Chocolate)
                            .margin(
                                topBottom = if (breakpoint >= Breakpoint.MD) 1.5.cssRem else 0.75.cssRem,
                                leftRight = if (breakpoint >= Breakpoint.MD) 1.5.cssRem else 0.75.cssRem
                            )
                            .animation(
                                HomeImageKeyFrame.toAnimation(
                                    duration = 0.5.s,
                                    iterationCount = AnimationIterationCount.Infinite,
                                    timingFunction = AnimationTimingFunction.EaseInOut,
                                    direction = AnimationDirection.Alternate
                                )
                            )
                    ) {
                        SpanText(
                            "Contact Us",
                            modifier = Modifier
                                .fillMaxHeight()
                                .align(Alignment.CenterVertically)
                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.5.cssRem else 0.75.cssRem)
                                .color(Colors.White)
                        )
                    }
                    Button(
                        onClick = {},
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(if (breakpoint >= Breakpoint.MD) 4.cssRem else 2.cssRem)
                            .backgroundColor(Colors.DarkBlue)
                            .margin(
                                topBottom = if (breakpoint >= Breakpoint.MD) 1.5.cssRem else 0.75.cssRem,
                                leftRight = if (breakpoint >= Breakpoint.MD) 1.5.cssRem else 0.75.cssRem
                            )
                            .animation(
                                HomeImageKeyFrame.toAnimation(
                                    duration = 0.5.s,
                                    iterationCount = AnimationIterationCount.Infinite,
                                    timingFunction = AnimationTimingFunction.EaseInOut,
                                    direction = AnimationDirection.Alternate
                                )
                            )
                    ) {
                        SpanText(
                            "Our Services",
                            modifier = Modifier
                                .fillMaxHeight()
                                .align(Alignment.CenterVertically)
                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.5.cssRem else 0.75.cssRem)
                                .color(Colors.White)
                        )
                    }
                }
            }
            Column(
                Modifier.fillMaxWidth()
                    .animation(
                        HomeImageKeyFrame.toAnimation(
                            1.s,
                            iterationCount = AnimationIterationCount.Infinite,
                            timingFunction = AnimationTimingFunction.EaseInOut,
                            direction = AnimationDirection.Alternate,
                            fillMode = AnimationFillMode.Both
                        )
                    ),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Image(
                    src = "home-img.png",
                    modifier = Modifier.fillMaxWidth(80.percent)
                )
            }
        }
    }

}