package com.vrajatcreations.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.CloseIcon
import com.varabyte.kobweb.silk.components.icons.HamburgerIcon
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.overlay.OverlayVars
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.vrajatcreations.components.widgets.IconButton
import com.vrajatcreations.style.NavHeaderStyle
import org.jetbrains.compose.web.css.*


@Composable
private fun NavLink(
    path: String,
    text: String
) {
    Link(
        path,
        text,
        variant = UndecoratedLinkVariant.then(UncoloredLinkVariant),
        modifier = Modifier.fontSize(1.cssRem)
    )
}

@Composable
private fun MenuItems() {
    NavLink("/", "Home")
    NavLink("/about", "About")
    NavLink("/our-team", "Our Team")
    NavLink("/contact-us", "Contact Us")
}

@Composable
private fun HamburgerButton(
    onClick: () -> Unit
) {
    IconButton(onClick) {
        HamburgerIcon()
    }
}

@Composable
private fun CloseButton(
    onClick: () -> Unit
) {
    IconButton(onClick) {
        CloseIcon()
    }

}


@Composable
fun NavHeader(breakpoint: Breakpoint) {
    Row(
        NavHeaderStyle.toModifier()
            .boxShadow(0.cssRem, 0.cssRem, 0.5.cssRem, 0.2.cssRem, rgba(0, 0, 0, 0.2)),
        verticalAlignment = Alignment.Top,
        horizontalArrangement = Arrangement.Center
    ) {
        Link("/") {
            Row {
                Image(
                    src = "logo.jpg",
                    modifier = Modifier.height(if (breakpoint >= Breakpoint.MD) 4.5.cssRem else 2.25.cssRem).weight(500)
                        .animation(
                            WobbleKeyframes.toAnimation(
                                duration = 1.s,
                                iterationCount = AnimationIterationCount.Infinite,
                                timingFunction = AnimationTimingFunction.EaseInOut,
                                direction = AnimationDirection.Alternate
                            )
                        )
                )
                Column(
                    modifier = Modifier
                        .align(Alignment.CenterVertically)
                ) {
                    SpanText(
                        "VRaJaT Creations",
                        modifier = Modifier
                            .fillMaxHeight()
                            .align(Alignment.CenterHorizontally)
                            .color(Color.rgb(47,49,144))
                            .padding(left = if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                            .fontWeight(900)
                            .fontSize(if (breakpoint >= Breakpoint.MD) 1.5.cssRem else 0.75.cssRem)
                    )
                    SpanText(
                        "Like to Say \"YES\" Always..!!",
                        modifier = Modifier
                            .align(Alignment.End)
                            .fillMaxHeight()
                            .color(Color.rgb(0, 0, 0))
                            .padding(left = if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                            .fontWeight(800)
                            .fontSize(if (breakpoint >= Breakpoint.MD) 0.7.cssRem else 0.35.cssRem)
                    )
                }
            }
        }
        Spacer()
        Row(
            Modifier
                .gap(1.5.cssRem)
                .displayIfAtLeast(Breakpoint.MD)
                .fillMaxHeight(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            MenuItems()
        }
        Row(
            modifier = Modifier
                .gap(1.cssRem)
                .displayUntil(Breakpoint.MD),
            verticalAlignment = Alignment.CenterVertically
        ) {
            var menuState by remember { mutableStateOf(SideMenuState.CLOSED) }
            HamburgerButton(
                onClick = { menuState = SideMenuState.OPEN }
            )
            if (menuState != SideMenuState.CLOSED) {
                SideMenu(
                    menuState = menuState,
                    close = { menuState = menuState.close() },
                    onAnimationEnd = {
                        if (menuState == SideMenuState.CLOSING) {
                            menuState = SideMenuState.CLOSED
                        }
                    }
                )
            }
        }
    }
}

enum class SideMenuState {
    CLOSED,
    OPEN,
    CLOSING;

    fun close() = when (this) {
        CLOSED -> CLOSED
        OPEN -> CLOSING
        CLOSING -> CLOSING
    }
}

@Composable
fun SideMenu(
    menuState: SideMenuState,
    close: () -> Unit,
    onAnimationEnd: () -> Unit
) {

    Overlay(
        Modifier.setVariable(OverlayVars.BackgroundColor, Colors.Transparent)
            .onClick { close() }
    ) {
        key(menuState) {
            Column(
                Modifier
                    .fillMaxHeight()
                    .width(clamp(8.cssRem, 33.percent, 10.cssRem))
                    .align(Alignment.CenterEnd)
                    .padding(top = 1.cssRem, leftRight = 1.cssRem)
                    .gap(1.5.cssRem)
                    .background(Colors.AntiqueWhite)
                    .animation(
                        SideMenuSlideInAnim.toAnimation(
                            duration = 200.ms,
                            timingFunction = if (menuState == SideMenuState.OPEN) AnimationTimingFunction.EaseOut else AnimationTimingFunction.EaseIn,
                            direction = if (menuState == SideMenuState.OPEN) AnimationDirection.Normal else AnimationDirection.Reverse,
                            fillMode = AnimationFillMode.Forwards
                        )
                    )
                    .borderRadius(topLeft = 2.cssRem)
                    .onClick { it.stopPropagation() }
                    .onAnimationEnd { onAnimationEnd() }
            ) {
                CloseButton(onClick = { close() })
                Column(
                    Modifier
                        .padding(0.75.cssRem)
                        .gap(1.5.cssRem)
                        .fontSize(1.4.cssRem),
                    horizontalAlignment = Alignment.End
                ) {
                    MenuItems()
                }
            }
        }

    }
}

val SideMenuSlideInAnim by Keyframes {
    from {
        Modifier.translateX(100.percent)
    }

    to {
        Modifier
    }
}

val WobbleKeyframes by Keyframes {
    from { Modifier.rotate((-5).deg) }
    to { Modifier.rotate(5.deg) }
}