package com.vrajatcreations.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.vh

val HeroContainerStyle by ComponentStyle {
    base {
        Modifier.fillMaxWidth().gap(2.cssRem)
    }

    Breakpoint.MD {
        Modifier.margin { top(20.vh) }
    }
}