package com.vrajatcreations.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import org.jetbrains.compose.web.css.cssRem

val ContactUsModifier by ComponentStyle {
    base {
        Modifier.fillMaxWidth()
            .padding(0.5.cssRem)
            .borderRadius(0.8.cssRem)
            .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f))
    }
}


val ContactUsSunIconStyle by ComponentStyle {
    base {
        Modifier
            .color(Colors.Red)
            .size(3.cssRem)
    }
}