package com.vrajatcreations.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.translateY
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.vrajatcreations.components.layout.PageLayout
import com.vrajatcreations.components.sections.*
import org.jetbrains.compose.web.css.percent

@Page("/")
@Composable
fun HomePage() {
    val ctx = rememberPageContext()
    val breakpoint = rememberBreakpoint()
    Box(
        Modifier.fillMaxSize(),
        contentAlignment = Alignment.Center
    ) {
        PageLayout("Home") {
            HomeLandingPage(breakpoint, ctx)
            OurServices(breakpoint)
            Technologies(breakpoint)
//            OurProjects(breakpoint)
            OurClients(breakpoint)
        }
    }
}

val HomeImageKeyFrame by Keyframes {
    from {
        Modifier.translateY((-3).percent)
    }
    to {
        Modifier
    }
}
