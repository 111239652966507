package com.vrajatcreations.util

import com.varabyte.kobweb.compose.ui.graphics.Color
import kotlin.js.Date


object Res {

    val COPY_RIGHT = "© Copyright ${Date().getFullYear()} VRaJaT Creations Sirwar. All Rights Reserved."

    val SKY_BLUE_COLOR = Color.rgb(39, 170, 228)

    val ourTeamList = arrayListOf(
        OurTeam(
            "Jayateertha Rao Karanam",
            "Managing Director, Chief Executive Officer",
            "With 35 years of multifaceted experience spanning farming, village accounting, petrol station operations, managerial roles in banking, and assistant management, He brings a wealth of diverse expertise to the table. His journey has seen him successfully manage small-scale industries including petrol stations and trusts, while also serving as a trusted advisor to petrol station owners on financial matters. Now, as the Managing Director and CEO, He leverage his comprehensive understanding of finance and operations to drive strategic growth and innovation. His commitment to excellence and a proven track record make me a dynamic leader poised to steer organizations towards success in today's competitive landscape."
        ),
        OurTeam(
            "Sandeep Karanam",
            "Co-Founder, Android Developer",
            "A 2015 graduate in BE CSE, he delved into diverse tech realms across various startups. From HTML, CSS, jQuery, and JavaScript to React.js in frontend, and Java, Kotlin, Spring MVC, Spring Boot, Ktor with JWT in backend, their proficiency spans the tech spectrum. Equally adept with databases, he worked with MySQL, PostgreSQL, Firebase Firestore, and MongoDB. Not stopping there, they've embraced Android, leveraging Jetpack components and Compose for cutting-edge solutions. In 2018, they co-founded a venture with their sister Bharati Nagaroor, launching a successful app on the Play Store. His journey epitomizes a relentless pursuit of innovation and entrepreneurial spirit."
        ),
        OurTeam(
            "Bharati Nagaroor",
            "Co-Founder, Chief Customer Officer",
            "A graduate of BE Electronics and Communication Engineering in 2015, she dedicated 7 years to Wipro, demonstrating excellence in the network department. Despite familial responsibilities urging her to quit, colleagues provided unwavering encouragement for her to persevere. Now, with a managerial recommendation, she has returned to Wipro. Beyond her corporate commitments, she passionately pursues painting and crafts. In 2018, she co-founded a venture with Sandeep Karanam, committed to continuous improvement. Their company strives to elevate every aspect, rallying individuals to join their cause. With a unique blend of technical expertise and creative passion, she embarks on a journey toward personal and professional fulfillment."
        ),
        OurTeam(
            "Anji Kumar",
            "Freelancer, Android Developer",
            "A graduate who excels in his work with unwavering commitment, he embarked on his journey as an Android trainee. Now, he oversees the management of the most critical online streaming platform, continuously evolving with his passion for technology. He is instrumental in transforming the company's perspective by initiating discussions on new product requirements and effectively implementing them. Each day, he grows and adapts, contributing significantly to the advancement and success of his team and the organization as a whole."
        )
    )

    val clients = arrayListOf(
        NameWithImage(
            "SVDSAK, Kowthalam",
            "svdsak.png"
        ),
        NameWithImage(
            "HSCT, Sirwar",
            "hsct.png"
        ),
        NameWithImage(
            "Qlucent, Bengaluru",
            "qlucent-logo.svg"

        ),
        NameWithImage(
            "Sai Bajaji Digitals, Hyderabad",
            "sbd.png"
        )
    )

    val services = arrayListOf(
        NameWithImage(
            "Android & iOS App Development",
            "app-development.jpeg"
        ),
        NameWithImage(
            "Website Design & Development",
            "responsive-website.jpg"
        ),
        NameWithImage(
            "Web App Development",
            "website-design.jpg"
        )
    )
}

data class OurTeam(
    val name: String,
    val designation: String,
    val about: String
)

data class NameWithImage(
    val name: String,
    val imageString: String
)