package com.vrajatcreations.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import org.jetbrains.compose.web.css.cssRem


val NavHeaderStyle by ComponentStyle.base {
    Modifier
        .fillMaxWidth()
        .padding(top = 1.cssRem, bottom = 0.5.cssRem, leftRight = 2.cssRem)
}