package com.vrajatcreations.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.InputSize
import com.varabyte.kobweb.silk.components.forms.TextInput
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.SunIcon
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.vrajatcreations.components.layout.PageLayout
import com.vrajatcreations.components.widgets.WhatsAppButton
import com.vrajatcreations.style.SlideDownAnim
import com.vrajatcreations.style.SlideLeftAnim
import com.vrajatcreations.style.SlideRightAnim
import org.jetbrains.compose.web.css.*

@Page("/contact-us")
@Composable
fun ContactUsPage() {

    val breakpoint = rememberBreakpoint()
    val ctx = rememberPageContext()

    var name by remember {
        mutableStateOf("")
    }
    var email by remember {
        mutableStateOf("")
    }
    var mobileNumber by remember {
        mutableStateOf("")
    }

    var comment by remember {
        mutableStateOf("")
    }
    Box(
        Modifier.fillMaxSize(), contentAlignment = Alignment.Center
    ) {
        PageLayout("Contact Us") {
            Row(
                Modifier.fillMaxWidth().animation(
                    SlideDownAnim.toAnimation(
                        1.s, timingFunction = AnimationTimingFunction.EaseIn, direction = AnimationDirection.Normal
                    )
                ).padding(0.5.cssRem).borderRadius(0.8.cssRem)
                    .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                SunIcon(
                    Modifier.align(Alignment.CenterVertically).color(Colors.Red)
                        .size(if (breakpoint >= Breakpoint.MD) 3.cssRem else 1.5.cssRem)
                )
                Column(
                    Modifier.fillMaxHeight().padding(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                        .align(Alignment.CenterVertically), verticalArrangement = Arrangement.Center
                ) {
                    SpanText(
                        text = "Have a Query or an Idea?",
                        modifier = Modifier.fillMaxHeight().align(Alignment.CenterHorizontally)
                            .fontSize(if (breakpoint >= Breakpoint.MD) 1.4.cssRem else 0.75.cssRem).fontWeight(850)
                            .color(Colors.Red)
                    )
                    SpanText(
                        "Let's connect to implement",
                        modifier = Modifier.fillMaxHeight().align(Alignment.End)
                            .margin(top = if (breakpoint >= Breakpoint.MD) 0.4.cssRem else 0.2.cssRem)
                            .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem).fontWeight(850)
                            .color(Colors.Black)
                    )
                }

                SunIcon(
                    Modifier.align(Alignment.CenterVertically).color(Colors.Red)
                        .size(if (breakpoint >= Breakpoint.MD) 3.cssRem else 1.5.cssRem)
                )
            }
            Row(
                Modifier.fillMaxWidth().margin(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem),
                horizontalArrangement = Arrangement.Center
            ) {
                SimpleGrid(
                    numColumns = numColumns(base = 1, md = 2), Modifier.fillMaxSize()
                ) {
                    Column(
                        Modifier.fillMaxWidth(95.percent).animation(
                            SlideRightAnim.toAnimation(
                                1.s,
                                timingFunction = AnimationTimingFunction.EaseIn,
                                direction = AnimationDirection.Normal
                            )
                        ).margin(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem).padding(0.5.cssRem)
                            .borderRadius(0.8.cssRem).boxShadow(
                                0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)
                            )
                    ) {
                        SpanText(
                            "Query/Request Form",
                            modifier = Modifier.fillMaxWidth().textAlign(TextAlign.Center)
                                .align(Alignment.CenterHorizontally)
                                .margin(top = if (breakpoint >= Breakpoint.MD) 0.4.cssRem else 0.2.cssRem)
                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.4.cssRem else 0.7.cssRem)
                                .color(Color.rgb(47, 49, 144))
                        )

                        TextInput(
                            text = name,
                            required = true,
                            placeholder = "Enter Name",
                            onTextChanged = {
                                name = it
                            },
                            size = InputSize.LG,
                            focusBorderColor = Color.rgb(47, 49, 144),
                            modifier = Modifier.fillMaxWidth(85.percent).align(Alignment.CenterHorizontally)
                                .margin(top = if (breakpoint >= Breakpoint.MD) 1.2.cssRem else 0.6.cssRem)
                                .padding(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                .color(Color.rgb(47, 49, 144))
                        )
                        TextInput(
                            text = email,
                            required = true,
                            placeholder = "Enter Email",
                            onTextChanged = {
                                email = it
                            },
                            size = InputSize.LG,
                            focusBorderColor = Color.rgb(47, 49, 144),
                            modifier = Modifier.fillMaxWidth(85.percent).align(Alignment.CenterHorizontally)
                                .margin(top = if (breakpoint >= Breakpoint.MD) 1.2.cssRem else 0.6.cssRem)
                                .padding(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                .color(Color.rgb(47, 49, 144))
                        )
                        TextInput(
                            text = mobileNumber,
                            required = true,
                            placeholder = "Enter Mobile Number",
                            onTextChanged = {
                                mobileNumber = it
                            },
                            size = InputSize.LG,
                            focusBorderColor = Color.rgb(47, 49, 144),
                            modifier = Modifier.fillMaxWidth(85.percent).align(Alignment.CenterHorizontally)
                                .margin(top = if (breakpoint >= Breakpoint.MD) 1.2.cssRem else 0.6.cssRem)
                                .padding(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                .color(Color.rgb(47, 49, 144))
                        )
                        TextInput(
                            text = comment,
                            required = true,
                            placeholder = "Enter Query or request",
                            onTextChanged = {
                                comment = it
                            },
                            size = InputSize.LG,
                            focusBorderColor = Color.rgb(47, 49, 144),
                            modifier = Modifier.fillMaxWidth(85.percent).align(Alignment.CenterHorizontally)
                                .margin(top = if (breakpoint >= Breakpoint.MD) 1.2.cssRem else 0.6.cssRem)
                                .padding(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                .color(Color.rgb(47, 49, 144))
                        )
                        Button(
                            onClick = {},
                            modifier = Modifier.fillMaxWidth(50.percent).align(Alignment.CenterHorizontally)
                                .backgroundColor(Color.rgb(47, 49, 144))
                                .height(if (breakpoint >= Breakpoint.MD) 3.cssRem else 1.5.cssRem)
                                .margin(topBottom = 1.5.cssRem)
                        ) {
                            SpanText(
                                "Submit",
                                modifier = Modifier.fillMaxHeight().align(Alignment.CenterVertically).fontSize(1.cssRem)
                                    .color(Colors.White)
                            )
                        }
                    }
                    Column(
                        Modifier.fillMaxWidth(95.percent).animation(
                            SlideLeftAnim.toAnimation(
                                1.s,
                                timingFunction = AnimationTimingFunction.EaseIn,
                                direction = AnimationDirection.Normal
                            )
                        ).margin(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem).padding(0.5.cssRem)
                            .borderRadius(0.8.cssRem).boxShadow(
                                0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)
                            )
                    ) {
                        Row(
                            Modifier.fillMaxWidth(),
                            horizontalArrangement = Arrangement.Center,
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            SimpleGrid(
                                numColumns(base = 1, md = 2)
                            ) {
                                Column(
                                    Modifier.fillMaxWidth()
                                        .margin(top = if (breakpoint >= Breakpoint.MD) 1.2.cssRem else 0.6.cssRem)
                                ) {
                                    SpanText(
                                        "VRaJaT Creations",
                                        modifier = Modifier.fillMaxHeight().align(Alignment.Start).margin(
                                            top = if (breakpoint >= Breakpoint.MD) 0.4.cssRem else 0.2.cssRem,
                                            left = if (breakpoint >= Breakpoint.MD) 2.cssRem else 1.cssRem
                                        ).fontSize(if (breakpoint >= Breakpoint.MD) 1.4.cssRem else 0.7.cssRem)
                                            .fontWeight(550).color(Color.rgb(34, 7, 247))
                                    )
                                    SpanText(
                                        "B B Patel Colony, Sirwar",
                                        modifier = Modifier.fillMaxHeight().align(Alignment.Start).margin(
                                            top = if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem,
                                            left = if (breakpoint >= Breakpoint.MD) 2.cssRem else 1.cssRem
                                        ).fontSize(1.cssRem).color(Colors.Black)
                                    )
                                    SpanText(
                                        "Raichur Dist, Karnataka - 584129",
                                        modifier = Modifier.fillMaxHeight().align(Alignment.Start).margin(
                                            top = if (breakpoint >= Breakpoint.MD) 0.4.cssRem else 0.2.cssRem,
                                            left = if (breakpoint >= Breakpoint.MD) 2.cssRem else 1.cssRem
                                        ).fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                            .color(Colors.Black)
                                    )
                                    Row(
                                        Modifier.fillMaxWidth().margin(
                                            top = if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem,
                                            left = if (breakpoint >= Breakpoint.MD) 2.cssRem else 1.cssRem
                                        ), verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Link("mailto:vrajatcreations@gmail.com") {
                                            FaEnvelope(
                                                modifier = Modifier.fillMaxHeight().color(Colors.BlueViolet),
                                                size = IconSize.LG
                                            )
                                            SpanText(
                                                "vrajatcreations@gmail.com",
                                                modifier = Modifier.fillMaxHeight()
                                                    .margin(left = if (breakpoint >= Breakpoint.MD) 0.5.cssRem else 0.25.cssRem)
                                                    .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                                    .color(Colors.BlueViolet)
                                            )
                                        }
                                    }

                                    Row(
                                        Modifier.fillMaxWidth().margin(
                                            topBottom = if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem,
                                            leftRight = if (breakpoint >= Breakpoint.MD) 2.cssRem else 1.cssRem
                                        ), verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        FaPhone(
                                            modifier = Modifier.color(Colors.BlueViolet), size = IconSize.LG
                                        )
                                        SpanText(
                                            "+91-944-89-13-955",
                                            modifier = Modifier.fillMaxHeight()
                                                .margin(left = if (breakpoint >= Breakpoint.MD) 0.5.cssRem else 0.25.cssRem)
                                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                                .color(Colors.BlueViolet)
                                        )
                                    }
                                }
                                Column(
                                    Modifier.fillMaxSize()
                                        .margin(top = if (breakpoint >= Breakpoint.MD) 1.2.cssRem else 0.6.cssRem),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.SpaceAround
                                ) {
                                    SpanText(
                                        "Registered at",
                                        modifier = Modifier.fillMaxHeight()
                                            .margin(left = if (breakpoint >= Breakpoint.MD) 0.5.cssRem else 0.25.cssRem)
                                            .fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                            .color(Colors.BlueViolet)
                                    )
                                    Image(
                                        src = "msme.png", Modifier.height(10.cssRem)
                                    )
                                    SpanText(
                                        "Reg No: UDYAM-KR-23-0011905", modifier = Modifier.fillMaxHeight().margin(
                                            left = if (breakpoint >= Breakpoint.MD) 0.5.cssRem else 0.25.cssRem,
                                            top = if (breakpoint >= Breakpoint.MD) 0.5.cssRem else 0.25.cssRem
                                        ).fontSize(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                            .color(Colors.BlueViolet)
                                    )

                                }
                            }

                        }
                        SpanText(
                            "We're on Social Platforms",
                            modifier = Modifier.fillMaxWidth().align(Alignment.CenterHorizontally)
                                .margin(top = if (breakpoint >= Breakpoint.MD) 0.4.cssRem else 0.2.cssRem)
                                .padding(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                                .fontSize(if (breakpoint >= Breakpoint.MD) 1.4.cssRem else 0.7.cssRem).fontWeight(550)
                                .textAlign(TextAlign.Center).color(Color.rgb(47, 49, 144))
                        )
                        Row(
                            Modifier.fillMaxWidth()
                                .margin(bottom = if (breakpoint >= Breakpoint.MD) 0.5.cssRem else 0.25.cssRem),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceEvenly
                        ) {
                            Link(
                                "https://www.facebook.com/vrajatcreations/",
                                openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
                                modifier = Modifier.align(Alignment.CenterVertically)
                            ) {
                                FaFacebook(
                                    modifier = Modifier.fillMaxHeight().color(Colors.Blue),
                                    size = if (breakpoint >= Breakpoint.MD) IconSize.LG else IconSize.SM
                                )
                                SpanText(
                                    "vrajatcreations",
                                    modifier = Modifier.fillMaxHeight()
                                        .margin(left = if (breakpoint >= Breakpoint.MD) 0.3.cssRem else 0.15.cssRem)
                                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.8.cssRem else 0.4.cssRem)
                                        .color(Colors.Blue)
                                )
                            }
                            Link(
                                "https://www.instagram.com/vrajat_creations",
                                openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
                                modifier = Modifier.align(Alignment.CenterVertically)
                            ) {
                                FaInstagram(
                                    modifier = Modifier.fillMaxHeight().color(Colors.HotPink),
                                    size = if (breakpoint >= Breakpoint.MD) IconSize.LG else IconSize.SM
                                )
                                SpanText(
                                    "vrajat_creations",
                                    modifier = Modifier.fillMaxHeight()
                                        .margin(left = if (breakpoint >= Breakpoint.MD) 0.3.cssRem else 0.15.cssRem)
                                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.8.cssRem else 0.4.cssRem)
                                        .color(Colors.HotPink)
                                )
                            }
                            Link(
                                "https://twitter.com/vrajatcreations",
                                openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
                                modifier = Modifier.align(Alignment.CenterVertically),
                            ) {
                                FaTwitter(
                                    modifier = Modifier.fillMaxHeight().color(Colors.Black),
                                    size = if (breakpoint >= Breakpoint.MD) IconSize.LG else IconSize.SM
                                )
                                SpanText(
                                    "vrajatcreations",
                                    modifier = Modifier.fillMaxWidth()
                                        .margin(left = if (breakpoint >= Breakpoint.MD) 0.3.cssRem else 0.15.cssRem)
                                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.8.cssRem else 0.4.cssRem)
                                        .color(Colors.Black)
                                )
                            }
                            Link(
                                "https://www.linkedin.com/company/vrajat-creations",
                                openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
                                modifier = Modifier.align(Alignment.CenterVertically),
                            ) {
                                FaLinkedin(
                                    modifier = Modifier.fillMaxHeight().color(Colors.Black),
                                    size = if (breakpoint >= Breakpoint.MD) IconSize.LG else IconSize.SM
                                )
                                SpanText(
                                    "vrajat-creations",
                                    modifier = Modifier.fillMaxWidth()
                                        .margin(left = if (breakpoint >= Breakpoint.MD) 0.3.cssRem else 0.15.cssRem)
                                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.8.cssRem else 0.4.cssRem)
                                        .color(Colors.Black)
                                )
                            }
                        }
                        Row(
                            Modifier.fillMaxWidth().margin(topBottom = 0.2.cssRem),
                            horizontalArrangement = Arrangement.End
                        ) {
                            Link("") {
                                SpanText(
                                    "Privacy Policy", modifier = Modifier.onClick {
                                        ctx.router.navigateTo("privacy-policy")
                                    }.margin(right = if (breakpoint >= Breakpoint.MD) 0.5.cssRem else 0.25.cssRem)
                                        .fontSize(if (breakpoint >= Breakpoint.MD) 0.6.cssRem else 0.3.cssRem)
                                        .color(Colors.BlueViolet)
                                )
                            }
                        }
                    }
                }
            }
            WhatsAppButton(breakpoint)
        }
    }
}

fun sendEmail() {
    val senderEmail = "vrajatcreations@gmail.com"
}
