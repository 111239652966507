package com.vrajatcreations.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaComputer
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.vrajatcreations.style.SlideDownAnim
import com.vrajatcreations.style.SlideRightAnim
import org.jetbrains.compose.web.css.*

@Composable
fun Technologies(
    breakpoint: Breakpoint
) {
    Row(
        Modifier.flexWrap(FlexWrap.Wrap)
            .margin(top = 3.cssRem)
            .animation(
                SlideDownAnim.toAnimation(
                    1.s,
                    timingFunction = AnimationTimingFunction.EaseIn,
                    direction = AnimationDirection.Normal
                )
            )
            .padding(0.5.cssRem)
            .borderRadius(0.8.cssRem)
            .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        FaComputer(
            Modifier
                .align(Alignment.CenterVertically)
                .color(Color.rgb(7,48,66)),
            size = IconSize.XXL
        )
        SpanText(
            text = "Our Apps builds on",
            modifier = Modifier
                .fontSize(if (breakpoint >= Breakpoint.MD) 1.4.cssRem else 0.75.cssRem)
                .fontWeight(800)
                .color(Color.rgb(25,133,243))
                .padding(if (breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
        )
        FaComputer(
            Modifier
                .align(Alignment.CenterVertically)
                .color(Color.rgb(7,48,66)),
            size = IconSize.XXL
        )
    }
    Row(
        Modifier.fillMaxWidth(90.percent)
            .animation(
                SlideRightAnim.toAnimation(
                    1.s,
                    timingFunction = AnimationTimingFunction.EaseIn,
                    direction = AnimationDirection.Normal
                )
            )
            .margin(1.cssRem)
            .padding(0.5.cssRem),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        SimpleGrid(
            numColumns = numColumns(base = 2, md = 5)
        ) {
            Column(
                Modifier.fillMaxWidth(90.percent)
                    .margin(topBottom = if (breakpoint < Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                    .borderRadius(0.8.cssRem)
                    .boxShadow(
                        0.05.cssRem,
                        0.05.cssRem,
                        0.3.cssRem,
                        0.05.cssRem,
                        Color.rgba(47, 49, 144, 0.4f)
                    ),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    Modifier.fillMaxWidth(90.percent)
                        .margin(0.5.cssRem),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    Image(
                        src = "android.png",
                        Modifier.fillMaxWidth(95.percent)
                    )
                }
            }
            Column(
                Modifier.fillMaxWidth(90.percent)
                    .margin(topBottom = if (breakpoint < Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                    .borderRadius(0.8.cssRem)
                    .boxShadow(
                        0.05.cssRem,
                        0.05.cssRem,
                        0.3.cssRem,
                        0.05.cssRem,
                        Color.rgba(47, 49, 144, 0.4f)
                    ),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    Modifier.fillMaxWidth(90.percent)
                        .margin(0.5.cssRem),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    Image(
                        src = "kotlin.png",
                        Modifier.fillMaxWidth(95.percent)
                    )
                }
            }
            Column(
                Modifier.fillMaxWidth(90.percent)
                    .margin(topBottom = if (breakpoint < Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                    .borderRadius(0.8.cssRem)
                    .boxShadow(
                        0.05.cssRem,
                        0.05.cssRem,
                        0.3.cssRem,
                        0.05.cssRem,
                        Color.rgba(47, 49, 144, 0.4f)
                    ),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    Modifier.fillMaxWidth(90.percent)
                        .margin(0.5.cssRem),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    Image(
                        src = "kobweb.png",
                        Modifier.fillMaxWidth()
                    )
                }
            }
            Column(
                Modifier.fillMaxWidth(90.percent)
                    .margin(topBottom = if (breakpoint < Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                    .borderRadius(0.8.cssRem)
                    .boxShadow(
                        0.05.cssRem,
                        0.05.cssRem,
                        0.3.cssRem,
                        0.05.cssRem,
                        Color.rgba(47, 49, 144, 0.4f)
                    ),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    Modifier.fillMaxWidth(90.percent)
                        .margin(0.5.cssRem),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    Image(
                        src = "jetpack-compose.png",
                        Modifier.fillMaxWidth()
                    )
                }
            }
            Column(
                Modifier.fillMaxWidth(90.percent)
                    .margin(topBottom = if (breakpoint < Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                    .borderRadius(0.8.cssRem)
                    .boxShadow(
                        0.05.cssRem,
                        0.05.cssRem,
                        0.3.cssRem,
                        0.05.cssRem,
                        Color.rgba(47, 49, 144, 0.4f)
                    ),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    Modifier.fillMaxWidth(90.percent)
                        .margin(0.5.cssRem),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    Image(
                        src = "ktor.png",
                        Modifier.fillMaxWidth()
                    )
                }
            }
        }
    }
}