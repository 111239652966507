package com.vrajatcreations.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.vrajatcreations.style.FooterStyle
import com.vrajatcreations.util.Res
import org.jetbrains.compose.web.css.cssRem


@Composable
fun Footer(
    modifier: Modifier = Modifier,
    breakpoint: Breakpoint
) {
    Box(
            FooterStyle.toModifier().then(modifier),
        contentAlignment = Alignment.Center
    ) {
        SpanText(
            Res.COPY_RIGHT,
            Modifier.fontSize(if(breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
        )
    }

}