package com.vrajatcreations.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.icons.fa.FaUsersRays
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.vrajatcreations.components.layout.PageLayout
import com.vrajatcreations.style.SlideDownAnim
import com.vrajatcreations.style.SlideRightAnim
import com.vrajatcreations.style.SlideUpAnim
import org.jetbrains.compose.web.css.*

@Page("/about")
@Composable
fun AboutPage() {
    val breakpoint = rememberBreakpoint()
    Box(
        Modifier.fillMaxSize(),
        contentAlignment = Alignment.Center
    ) {
        PageLayout("About") {
            Row(
                Modifier.fillMaxWidth()
                    .position(Position.Relative)
                    .animation(
                        SlideDownAnim.toAnimation(
                            1.s,
                            timingFunction = AnimationTimingFunction.EaseIn,
                            direction = AnimationDirection.Normal
                        )
                    )
                    .padding(0.5.cssRem)
                    .borderRadius(0.8.cssRem)
                    .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                FaUsersRays(
                    Modifier
                        .align(Alignment.CenterVertically)
                        .color(Color.rgb(47, 49, 144)),
                    size = IconSize.XXL
                )
                SpanText(
                    text = "We Are VRaJaTians",
                    modifier = Modifier
                        .fontSize(if(breakpoint >= Breakpoint.MD) 1.2.cssRem else 0.6.cssRem)
                        .fontWeight(550)
                        .color(Color.rgb(47, 49, 144))
                        .padding(1.cssRem)
                )
                FaUsersRays(
                    Modifier
                        .align(Alignment.CenterVertically)
                        .color(Color.rgb(47, 49, 144)),
                    size = IconSize.XXL
                )
            }
            Column(
                Modifier.fillMaxWidth()
                    .position(Position.Relative)
                    .animation(
                        SlideRightAnim.toAnimation(
                            1.s,
                            timingFunction = AnimationTimingFunction.EaseIn,
                            direction = AnimationDirection.Normal
                        )
                    )
                    .margin(1.cssRem)
                    .padding(1.cssRem)
                    .borderRadius(1.cssRem)
                    .boxShadow(0.05.cssRem, 0.05.cssRem, 0.1.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.Start
            ) {
                SpanText(
                    text = "In Sanskrit, VRaJaT signifies progress. VRaJaT Creations, headquartered in Sirwar, Raichur District, Karnataka, functions as a proprietary establishment registered under MSME. It is guided by our father, Karanam Jayateertharao Kowthalam, a versatile individual with proficiency in farming, mechanics, petrol bunk management, and banking. Our journey commenced with just two members, my sister and myself, propelled by the teachings of our literary mentor, Shri Gurujagannathadasa. Our primary focus was digitizing content, which serves as the cornerstone of our sister association, named “Shri Varadavithala Dasa Sahitya Adhyayana Kendra, Kowthalam Sirwar.” This endeavor led to the introduction of our inaugural app, initially titled Shri Gurujagannathadasa, later rebranded as Nithya Parayana, presently accessible on the Play Store and soon to be available on iOS.",
                    modifier = Modifier
                        .fontSize(if(breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "Supported by our well-wishers, we've expanded our repertoire with two additional apps, Shree and Guruve Gajanana, both accessible on the Play Store. Hailing from a remote village with sparse connectivity, we embarked on our educational path, eventually transitioning into the realm of entrepreneurship. While our progress may echo the steady pace of a tortoise, we embrace it wholeheartedly, finding fulfillment in our journey and maintaining a sense of inner satisfaction.",
                    modifier = Modifier
                        .fontSize(if(breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
                SpanText(
                    text = "Driven by our passion, dedication, and unwavering ethical standards, we are committed to contributing to the technological advancement of startups and organizations. Our ultimate goal is to establish a team and extend our support to underserved Tier 4 cities. We prioritize projects that allow for thorough attention to detail and client satisfaction, gradually expanding our scope to accommodate tighter deadlines while maintaining our commitment to excellence and user satisfaction.",
                    modifier = Modifier
                        .fontSize(if(breakpoint >= Breakpoint.MD) 0.9.cssRem else 0.6.cssRem)
                        .color(Colors.Black)
                        .padding(0.5.cssRem)
                )
            }
            Column(
                Modifier.fillMaxWidth()
                    .position(Position.Relative)
                    .animation(
                        SlideUpAnim.toAnimation(
                            1.s,
                            timingFunction = AnimationTimingFunction.EaseIn,
                            direction = AnimationDirection.Normal
                        )
                    )
                    .padding(0.5.cssRem)
                    .borderRadius(0.8.cssRem)
                    .boxShadow(0.05.cssRem, 0.05.cssRem, 0.3.cssRem, 0.05.cssRem, Color.rgba(47, 49, 144, 0.4f)),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                SpanText(
                    "For any website, web app, or mobile development needs, don't hesitate to reach out to us",
                    modifier = Modifier
                        .fontSize(if(breakpoint >= Breakpoint.MD) 1.cssRem else 0.5.cssRem)
                        .color(Color.rgb(47, 49, 144))
                        .padding(0.2.cssRem)
                )
            }
        }
    }
}